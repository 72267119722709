import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert'
import ReactGA from "react-ga4";

const FitnessGoalsScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [goal, setGoal] = useState('Lose Weight');
    const [selectedDays, setSelectedDays] = useState([]);
    const [workoutType, setWorkoutType] = useState('Home Workout'); // Default: Home Workout
    const [experienceLevel, setExperienceLevel] = useState('Beginner'); // Default: Beginner

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const fitnessGoals = ['Lose Weight', 'Gain Muscle Mass'];
    const workoutTypes = ['Home Workout', 'GYM Workout'];
    const experienceLevels = ['Beginner', 'Intermediate', 'Advanced'];

    const handleSubmission = () => {
        console.log(selectedDays)
        // Check conditions and navigate if everything is okay
        if (selectedDays.length >= 3 && selectedDays.length <= 6)
        {
            // Update local storage
            localStorage.setItem('user_fitness_goal', goal);
            localStorage.setItem('user_preferred_workout_days', JSON.stringify(selectedDays));
            localStorage.setItem('user_workout_type', workoutType);
            localStorage.setItem('user_experience_level', experienceLevel);

            navigate('/userinfo');
        } else {
            dispatchAlert(dispatch,"alert","Please select between 3 and 6 workout days before procceding");
        }
    };

    const toggleDay = (day) => {
        setSelectedDays(prevDays => {
            if (prevDays.includes(day)) {
                return prevDays.filter(d => d !== day);
            } else {
                return [...prevDays, day];
            }
        });
    };

    const handleSelectGoal = (selectedGoal) => {
        setGoal(selectedGoal);
    };

    const initAnalytics = () => {
        ReactGA.send({
            hitType: "pageview",
            page: "/fitness-goals",
            title: "Fitness Goals Screen"
        });
    };

    useEffect(() => {
        initAnalytics();
    }, []);

    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-xl mt-2 leading-none sm:text-3xl md:text-4xl lg:text-5xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Set Your Fitness Goals
            </h1>
            
            <div className="space-y-4 bg-slate-900 p-4 md:p-8 rounded-lg shadow-lg w-full sm:w-3/4 lg:w-1/2 xl:w-1/3">
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mb-4">
                    Please select your fitness goal:
                </h6>
    
                <div className="flex flex-col space-y-4">
                    {fitnessGoals.map(fitnessGoal => (
                        <button 
                            key={fitnessGoal}
                            className={`bg-${goal === fitnessGoal ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                            onClick={() => handleSelectGoal(fitnessGoal)}>
                            {fitnessGoal}
                        </button>
                    ))}
                </div>

            {/* UI for new question: Experience Level */}
            <div className="w-full border-t border-gray-600 mt-4"></div>
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                  What is your experience level?
                </h6>
                <div className="flex flex-col space-y-4">
                  {experienceLevels.map(level => (
                    <button 
                      key={level}
                      className={`bg-${experienceLevel === level ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                      onClick={() => setExperienceLevel(level)}>
                      {level}
                    </button>
                  ))}
                </div>
    
                <div className="w-full border-t border-gray-600 mt-4"></div>
    
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                    Please select the days you'd like to workout:
                </h6>
                <span className="text-gray-400 text-sm">(Select at least 3, 5 for best results, 6 max)</span>
    
                <div className="flex flex-wrap justify-center mt-2">
                    {daysOfWeek.map(day => (
                        <button
                            key={day}
                            className={`m-1 px-3 py-1.5 rounded-md text-white ${selectedDays.includes(day) ? 'bg-blue-500' : 'bg-gray-900'} hover:bg-slate-700`}
                            onClick={() => toggleDay(day)}>
                            {day}
                        </button>
                    ))}
                </div>

                <div className="w-full border-t border-gray-600 mt-4"></div>
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                  Which kind of workout do you prefer?
                </h6>
                <div className="flex flex-col space-y-4">
                  {workoutTypes.map(type => (
                    <button 
                      key={type}
                      className={`bg-${workoutType === type ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                      onClick={() => setWorkoutType(type)}>
                      {type}
                    </button>
                  ))}
                </div>
    
                <button
                    className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full mt-4 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                    onClick={handleSubmission}>
                    Submit
                </button>
    
            </div>
        </div>
    );
    
};

export default FitnessGoalsScreen;
