import React, { useEffect, useState } from 'react';
import youtube from '../assets/main/youtube.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from './Utils/Functions/DispatchAlert'
import ProUpgradeOverlay from './Utils/Components/ProOverlay'; // Adjust the path accordingly
import ReactGA from "react-ga4";

const WorkoutPlans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [workoutData, setWorkoutData] = useState([]);
  const [selectedDay, setSelectedDay] = useState('Day 1');
  const [workoutReplaced, setWorkoutReplaced] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [workoutsBeingReplaced, setWorkoutsBeingReplaced] = useState([]);
  const [showProOverlay, setShowProOverlay] = useState(false);
  const dietItemsModifying = useSelector((state) => state.diet.dietItemsModifying);
  const [email,setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName,setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID,setUserID] = useState(localStorage.getItem('user_id') || '');
  const [isFirstVisit, setIsFirstVisit] = useState(true);

  var url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`

  const dayOrder = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7,
  };

  useEffect(() => {
    let overlayTimer;
    // Remove or comment out the initial useEffect that shows the overlay
    /*
    const showProOverlayIfNeeded = () => {
      const isProUser = ProIntercept(); // Reuse the existing ProIntercept function
      const workoutDataExists = localStorage.getItem('userWorkoutData');
      const isWorkoutDataValid = workoutDataExists && workoutDataExists !== 'null' && workoutDataExists !== '[]';

      // Ensure there's valid workout data before proceeding with the Pro overlay logic
      if (isWorkoutDataValid) {
        const workoutFirstLoadCompleted = localStorage.getItem('workoutFirstLoadCompleted');

        if (!workoutFirstLoadCompleted) {
          localStorage.setItem('workoutFirstLoadCompleted', 'true');
          // Delay the overlay for 10 seconds to give users some context before the prompt
          overlayTimer = setTimeout(() => {
            if (!isProUser) {
              setShowProOverlay(true);
            }
          }, 10000); // You can adjust this delay based on your UX preferences
        } else {
          // For subsequent visits or if the data is immediately available, check Pro status immediately
          if (!isProUser) {
            setShowProOverlay(true);
          }
        }
      }
    };

    showProOverlayIfNeeded();

    // Cleanup to clear the timeout if the component unmounts
    return () => {
      if (overlayTimer) {
        clearTimeout(overlayTimer);
      }
    };
    */
  }, []); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    console.log('WorkoutPlans.js useEffect called');
    const user_age = localStorage.getItem('user_age');
    const session = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
    const user_fitness_goal = localStorage.getItem('user_fitness_goal');
    const userCalories = localStorage.getItem('userCalories');
    const user_food_preference = localStorage.getItem('user_food_preference');
    const user_gender = localStorage.getItem('user_gender');
    const user_tdee = localStorage.getItem('user_tdee');
    const user_weight_in_kgs = localStorage.getItem('user_weight_in_kgs');
    const user_height_in_cm = localStorage.getItem('user_height_in_cm');
    const user_avoid_food = localStorage.getItem('user_avoid_food');
    const user_activity = localStorage.getItem('user_activity');
    const user_diet_preference = localStorage.getItem('user_diet_preference');
    const userPreferences = localStorage.getItem('userPreferences');
    const user_goal_tdee = localStorage.getItem('user_goal_tdee');
    const user_allergies = localStorage.getItem('user_allergies');

    // Check if workout data is available in local storage
    const localWorkoutData = JSON.parse(localStorage.getItem('userWorkoutData'));
    const last_selected_day = localStorage.getItem('last_selected_day') || 'Day 1';
    console.log('localWorkoutData :', localWorkoutData);
    const sessionObj = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));

    setSelectedDay(last_selected_day);  // Set the default selected day from local storage or default to Day 1

    if (localWorkoutData) {
      console.log('localWorkoutData exists:',localWorkoutData)
      setWorkoutData(localWorkoutData);
    } else {
      console.log('localWorkoutData does not exist, calling the fetch method')
      fetchData(false);
    }

    if(user_goal_tdee){

      // Parsing the JSON token object
      const parsedTokenData = JSON.parse(session);

      // Extracting the user ID from the parsed token object
      const user_id = parsedTokenData.user.id;
      // Set this data in the state in JSON format
      setUserData({
        user_id,
        user_age: parseInt(user_age, 10),
        user_fitness_goal,
        user_goal_tdee : parseFloat(user_goal_tdee),
        user_allergies,
        userCalories,
        user_food_preference,
        user_gender,
        user_tdee: parseFloat(user_tdee),
        user_weight_in_kgs: parseFloat(user_weight_in_kgs),
        user_height_in_cm: parseFloat(user_height_in_cm),
        user_avoid_food,
        user_activity,
        user_diet_preference,
        userPreferences,
      });

    }
    else{
      navigate('/goal');
    }
  }, []); 

  useEffect(() => {
    // Fetch data or handle changes based on dietItemsModifying
    console.log('dietItemsModifying:', dietItemsModifying);
    if (dietItemsModifying.length > 0) {
      // Logic to reload the diet plan from the local storage
      const storedWorkoutData = localStorage.getItem('userWorkoutData');
      if (storedWorkoutData) {
        setWorkoutData(JSON.parse(storedWorkoutData));
        setSelectedDay(localStorage.getItem('last_selected_day') || 'Day 1')
      }
    }
  }, [dietItemsModifying]);

  useEffect(() => {
    if (workoutData && workoutData.length > 0) {
      if (!workoutReplaced){
        setSelectedDay(workoutData[0].Day); // assuming each item in data has a 'day' property
      }
      setWorkoutReplaced(false)
    }
  }, [workoutData]);

  const ProIntercept = () => {
    // Check if 'user_pro' exists in localStorage
    if (!localStorage.getItem('user_pro')) {
        localStorage.setItem('user_pro', '0');  // Set it to '0' if it doesn't exist
    }

    const userProValue = parseInt(localStorage.getItem('user_pro'), 10);
    return userProValue !== 0;
  }

  const checkReplaceUsed = () => {

    let userReplacedValue = parseInt(localStorage.getItem('user_replaced'), 10);

    // If user_replaced doesn't exist, set it to 1
    if (isNaN(userReplacedValue)) {
      localStorage.setItem('user_replaced', '1');
      return false;
    } else if (userReplacedValue >= 3) {
      return true; // Exit the function early
    } else {
      // Increment the user_replaced value by 1
      userReplacedValue += 1;
      localStorage.setItem('user_replaced', userReplacedValue.toString());

      dispatchAlert(dispatch, "alert", 
      <>You've done {userReplacedValue}/3 free replacements 
          <a href={url} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="text-blue-500 underline">
              Upgrade to Pro 
          </a> 
      for unlimited meals and replacements and workouts</>
      );
      return false;
    }

  }

  const fetchData = async (buttonPress) => {
    if(buttonPress){
      var isPro = ProIntercept();
      if(!isPro){
        setShowProOverlay(true)
        return;
      }
    }

    console.log('calling fetch method')
    const session = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');

    // Parsing the JSON token object
    const parsedTokenData = JSON.parse(session);

    // Extracting the user ID from the parsed token object
    const user_id = parsedTokenData.user.id;
  // Set this data in the state in JSON format
    const dataToSend = {
      "user_id": user_id,
      "userGoal": localStorage.getItem('user_fitness_goal'),
      "user_preferred_workout_days": localStorage.getItem('user_preferred_workout_days'),
      "user_experience_level": localStorage.getItem('user_experience_level'),
      "user_workout_type": localStorage.getItem('user_workout_type'),
      "buttonPress": buttonPress,
    };
    console.log('dataToSend :', dataToSend);
    setIsLoading(true);
    const sessionObj = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));

    fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/openai_get_workout_plan', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionObj['access_token']}`
        },
        body: JSON.stringify(dataToSend),
    })
    .then(response => response.json())
    .then(data => {
        setIsLoading(false);
        var updatedWorkoutData = addWarmupCooldownToWorkoutData(data);
        setWorkoutData(updatedWorkoutData);
        localStorage.setItem('userWorkoutData', JSON.stringify(updatedWorkoutData));
        console.log('Workout data received:', data);
        
        // Track workout plan generation
        window.gtag('event', 'WEB_workout_plan_generated', {
          buttonPress: buttonPress
        });
        
        displayAlert();
    })
    .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
    });
  }

  const saveCurrentPlan = async () => {
    try {
      // Fetch values from local storage
      const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
      const user_fitness_goal = localStorage.getItem('user_fitness_goal');

      const user_preferred_workout_days = localStorage.getItem('user_preferred_workout_days');
      const user_workout_type = localStorage.getItem('user_workout_type');
      const user_experience_level = localStorage.getItem('user_experience_level');
      const workout_plan = localStorage.getItem('userWorkoutData');

      const parsedTokenData = JSON.parse(sessionString);
      const user_id = parsedTokenData.user.id;
      const write_table = 'saved_workouts';
      const payload = {
        user_fitness_goal,
        user_preferred_workout_days,
        user_workout_type,
        user_experience_level,
        workout_plan,
        write_table,
      };

      console.log(`saved plan called - ${selectedDay}`);
      console.log(payload);

      setIsLoading(true);
      fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${parsedTokenData['access_token']}`
          },
          body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        console.log("diet data saved successfully")
        setIsLoading(false);
    })
      .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
      });

  } catch (err) {
      console.error('An error occurred:', err);
      setIsLoading(false);
  }

  }

  // const workoutsForSelectedDay = selectedDay ? workoutData.filter(workout => workout.Day === selectedDay) : [];

  const getUniqueBodyPartsForDay = (day) => {
    const workoutsForDay = workoutData.filter(workout => workout.Day === day);
    var bodyParts = [...new Set(workoutsForDay.map(workout => workout['Body Part Targeted']))];
    console.log(`bodyParts for day ${day}:`, bodyParts);
    return bodyParts;
  };

  const displayDayName = (day) => {
    if (!day) return '';  // Return an empty string if day is undefined

    // Check if the day is a rest day
    if (day.toLowerCase().includes('rest')) {
        return "Rest Day";
    }
    return day;
  }

  const replaceWorkout = async (workoutToReplace) => {

    var isPro = ProIntercept();
    if (!isPro){
      console.log("not a pro user")
      var replaceUsed =   checkReplaceUsed()
      if(replaceUsed){
          dispatchAlert(dispatch, "alert", <>You've reached the maximum number of replacements! 
          <a href={url} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="text-blue-500 underline">
              Upgrade to Pro 
          </a> 
          for unlimited meals and replacements and workouts</>);
          return;
      }
    }

    console.log('workoutToReplace :', workoutToReplace);
    // Adding the workout to the workoutsBeingReplaced state
    setWorkoutsBeingReplaced(prevWorkouts => [...prevWorkouts, workoutToReplace['id']]);

    const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
    const sessionObj = JSON.parse(sessionString);
    setWorkoutReplaced(true)
    try {
      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/replace_workout_item', { 
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionObj['access_token'] || sessionObj['provider_token']}`
        },
        body: JSON.stringify({
          workoutToReplace: workoutToReplace,
          userData: userData,
          current_workout_data : localStorage.getItem('userWorkoutData'),
          // Add any other necessary data
        })
      }); 

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${await response.text()}`);
      }

      const data = await response.json();

      // If there's a replacement workout returned from the backend:
      if (data.replacementWorkout) {
        const parsedReplacementWorkout = JSON.parse(data.replacementWorkout);
        console.log('parsedReplacementWorkout:', parsedReplacementWorkout);

        const updatedWorkoutData = workoutData.map(workout => {
          if (workout.id === workoutToReplace.id) {
            return parsedReplacementWorkout;
          }
          return workout;
        });

        setWorkoutData(updatedWorkoutData);
        setWorkoutsBeingReplaced(prevWorkouts => prevWorkouts.filter(workout => workout !== parsedReplacementWorkout['id']));
        localStorage.setItem('userWorkoutData', JSON.stringify(updatedWorkoutData));
      }
    } catch (error) {
      console.error('Error replacing workout:', error);
    }
  };

  // Helper function to generate a unique ID
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }

  const addWarmupCooldownToWorkoutData = (data) => {
    const uniqueDays = [...new Set(data.map(item => item.Day))];

    const warmupTemplate = {
      "id": "",
      "Day": "",
      "Workout Name": "Warmup",
      "Body Part Targeted": "Warmup",
      "Youtube Video Link": "https://www.youtube.com/watch?v=divaflydT7M",
      "No of Sets": "1",
      "No of Reps": "1"
    };

    const cooldownTemplate = {
      "id": "",
      "Day": "",
      "Workout Name": "Cooldown",
      "Body Part Targeted": "Cooldown",
      "Youtube Video Link": "https://www.youtube.com/watch?v=Qy3U09CnELI",
      "No of Sets": "1",
      "No of Reps": "1"
    };

    const newData = [];

    uniqueDays.forEach(day => {
      // Add warmup for current day
      const warmup = { ...warmupTemplate, Day: day, id: generateUniqueId() };
      newData.push(warmup);

      // Add original workouts for current day
      const dayWorkouts = data.filter(workout => workout.Day === day);
      newData.push(...dayWorkouts);

      // Add cooldown for current day
      const cooldown = { ...cooldownTemplate, Day: day, id: generateUniqueId() };
      newData.push(cooldown);
    });

    return newData;
  }

  // New function to handle day selection and Pro check
  const handleDaySelection = (dayNumber) => {
 

    const isProUser = ProIntercept();
    const uniqueDays = [...new Set(workoutData.map(item => item.Day))]
      .sort((a, b) => dayOrder[a] - dayOrder[b]);
    const firstDay = uniqueDays[0];

    if (dayNumber !== firstDay) {
      if (!isProUser) {
        setShowProOverlay(true);
      }
      else{
        setSelectedDay(dayNumber);

        localStorage.setItem('last_selected_day', dayNumber);
      }
    } else{
      setSelectedDay(dayNumber);

      localStorage.setItem('last_selected_day', dayNumber);
    }
  }

  useEffect(() => {
    if (workoutData && workoutData.length > 0) {
      displayAlert();
    }
  }, [workoutData]);

  const displayAlert = async() => {
    const hasVisited = localStorage.getItem('hasVisitedWorkoutScreen');
    
    if (!hasVisited) {
      // Add a delay before showing the alert

        localStorage.setItem('hasVisitedWorkoutScreen', 'true');
        
        const userConfirmed = window.confirm(
          "Your custom taylored healthy workoutplan plan is generated, Lets me introduce you to your 24/7 AI personal trainer, please click the bttom right chat button"
        );
  
        if (userConfirmed) {
          navigate('/workouts');
        }
    } else {
      setIsFirstVisit(false);
    }
  };

  // Add analytics tracking
  useEffect(() => {
    try {
      // Track screen view
      window.gtag('event', 'screen_view', {
        screen_name: 'WEB_Workouts_Screen'
      });
      
      // Track user in workouts screen
      window.gtag('event', 'WEB_user_in_Workouts_Screen');
      
      console.log('Analytics initialized successfully Workouts_Screen');
    } catch (error) {
      console.error('Error initializing analytics Workouts_Screen:', error);
    }
  }, []);

  if (isLoading) {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-xl mt-2 leading-none sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Your Workout Plan
            </h1>
            <svg className="animate-spin h-5 w-5 text-white mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <p className="text-base sm:text-lg md:text-xl text-gray-100 dark:text-white text-center">Loading workout plans, please wait for few minutes to curate your custom workout plan...</p>
        </div>
    );
}

return (
  <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-1 sm:p-2 space-y-1 sm:space-y-2">
    <h1 className="text-base mt-1 leading-snug sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
        Your Workout Plan
    </h1>

    {isLoading ? (
        <p className="text-sm sm:text-base md:text-lg text-gray-300 text-center">Loading workout plans...</p>
    ) : (
        <div className="flex flex-wrap gap-2 sm:gap-4 mb-4 w-full justify-center">
      {
        [...new Set(workoutData.map(item => item.Day))]
          .sort((a, b) => dayOrder[a] - dayOrder[b])
          .map(dayNumber => (
          <button 
            key={dayNumber} 
            onClick={() => handleDaySelection(dayNumber)}  // Use the new function here
            className={`px-6 py-2 rounded-full ${selectedDay === dayNumber ? 'bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 text-white' : 'bg-slate-900 text-gray-300 hover:bg-slate-800'}`}
          >
            {displayDayName(dayNumber)}
          </button>
        ))
      }
    </div>
  )}

  {selectedDay && (
      <div className="workout-details bg-slate-900 p-2 sm:p-4 rounded-lg shadow-lg w-full space-y-1 sm:space-y-2 max-w-4xl">
          {
              getUniqueBodyPartsForDay(selectedDay).map(bodyPart => {
                  const workoutsForBodyPart = workoutData.filter(workout => workout.Day === selectedDay && workout['Body Part Targeted'] === bodyPart);
                  return (
                      <div key={bodyPart} className="bodypart-section flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-2 mb-4">
                          <h4 className="text-xl font-semibold mb-1 text-gray-100 w-2/5 sm:w-1/4">{bodyPart}</h4>
                          <div className="workouts flex-1 flex flex-wrap gap-2 sm:gap-3 justify-center sm:justify-start">
                              {
                                  workoutsForBodyPart.map((workout, workoutIndex) => {
                                    const itemStyle = "max-w-xs sm:w-1/2 lg:w-1/4 sm:max-w-full";  // max-w-xs for mobile and overriding with full width for larger screens
                                    if (workoutsBeingReplaced.includes(workout.id) || dietItemsModifying.includes(workout.id)) {
                                      return (
                                          <div key={workoutIndex} className={`workout-item ${itemStyle} border border-gray-700 bg-gray-800 rounded-md p-3 sm:p-6 hover:bg-gray-700 transition-colors duration-300 flex flex-col justify-center items-center`}>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </div>
                                        );
                                    } else {
                                        return (
                                          <div key={workoutIndex} className={`workout-item ${itemStyle} border border-gray-700 bg-gray-800 rounded-md p-2 sm:p-4 hover:bg-gray-700 transition-colors duration-300 flex flex-col justify-start`}>
                                            <h5 className="text-base sm:text-lg font-medium mb-1 text-white">{workout['Workout Name']}</h5>
                                              <p className="mb-1 text-xs sm:text-sm text-gray-300"><strong className="font-semibold text-gray-100">No of Sets:</strong> {workout['No of Sets']}</p>
                                              <p className="mb-1 text-xs sm:text-sm text-gray-300"><strong className="font-semibold text-gray-100">No of Reps:</strong> {workout['No of Reps'] || workout['No of Reps ']}</p>

                                              <div className="flex justify-center items-center mt-1 sm:mt-2">
                                              <img 
                                                  src={youtube} 
                                                  alt="Watch on YouTube" 
                                                  onClick={() => window.open(workout['Youtube Video Link'], '_blank')} 
                                                  className="w-10 h-6 mr-1 sm:mr-2 cursor-pointer hover:opacity-80 transition-opacity duration-300"
                                              />
                                                {!(workout['Workout Name'] === 'Warmup' || workout['Workout Name'] === 'Cooldown') && 
                                                              <button onClick={() => replaceWorkout(workout)} className="px-2 sm:px-3 py-0.5 sm:py-1 text-xs sm:text-sm bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors duration-300">Replace</button>
                                                            }                                              </div>
                                          </div>
                                      );
                                  }
                              })
                            }
                            </div>
                          </div>
                      );
                  })
                }
                <div className="w-full flex justify-center mt-12">
                    <button 
                        onClick={() => fetchData(true)} 
                        className="mt-6 bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 focus:outline-none shadow-md"
                    >
                        Reset my workout plan
                    </button>
                    {<button 
                        onClick={() => saveCurrentPlan()} 
                        className="ml-5 mt-6 bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 focus:outline-none shadow-md"
                    >
                        {"Save Plan"}
                    </button>}
                </div>

          </div>
        )}

        {showProOverlay && (
            <ProUpgradeOverlay 
                isVisible={showProOverlay} 
                onClose={() => setShowProOverlay(false)} 
                onUpgrade={() => {
                    // Handle the logic for upgrading to Pro here
                    setShowProOverlay(false);
                }}
            />
        )}
      </div>
    );
}  

export default WorkoutPlans;
