import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert';
import NavButtons from '../Utils/Components/NavButtons';
import { getUserData } from '../Auth/CompleteAuth';
import ReactGA from "react-ga4";

const UpdateOthers = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Existing state variables
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [email, setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName, setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID, setUserID] = useState(localStorage.getItem('user_id') || '');

  const url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`;

  const [loading, setLoading] = useState(false);

  // New state variables for cancellation
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // New state variables for restoring purchase
  const [restoring, setRestoring] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const initAnalytics = () => {
      ReactGA.send({
        hitType: "pageview",
        page: "/settings/others",
        title: "Update Others"
      });
    };

    initAnalytics();
  }, []);

  const handleContinuePro = () => {
    window.open(url, '_blank'); // '_blank' opens the URL in a new tab
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const fetchedData = await getSubscriptionData(session['access_token']);
      setSubscriptionInfo(fetchedData);
      console.log('Fetched Data:', fetchedData);
      localStorage.setItem('subscription_data', JSON.stringify(fetchedData));
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptionData = async (sessionObj) => {
    console.log('getSubscriptionData called');
    try {
      const response = await fetch(
        'https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_user_data',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionObj}`,
          },
          body: JSON.stringify({ table: 'user_subscriptions' }),
        }
      );

      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const restorePurchase = async () => {
    setRestoring(true); // Start loading

    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        throw new Error('Failed to retrieve session.');
      }

      if (session) {
        const accessToken = session.access_token;
        const route = await getUserData(accessToken);
        if (route) {
          console.log('User data is updated');
          dispatchAlert(dispatch, 'success', 'Purchase restored successfully.');
        } else {
          console.log('User data is not updated');
          dispatchAlert(dispatch, 'error', 'Failed to restore purchase.');
        }
        localStorage.setItem('lastExecutionTimestamp', Date.now().toString());
      } else {
        dispatchAlert(dispatch, 'error', 'No active session found.');
      }
    } catch (error) {
      dispatchAlert(dispatch, 'error', 'An error occurred while restoring purchase.');
      console.error('Restore Purchase Error:', error);
    } finally {
      setRestoring(false); // End loading
    }
  };

  // Function to open cancellation modal
  const cancelSubscription = () => {
    setShowCancelModal(true);
  };

  // Handle submission of cancellation with reason
  const handleCancelSubmit = async (e) => {
    e.preventDefault();

    // Validate that a reason is selected
    if (!cancelReason) {
      dispatchAlert(dispatch, 'error', 'Please select a reason for cancellation.');
      return;
    }

    // If 'Other' is selected, ensure the user provided a reason
    if (cancelReason === 'Other' && !otherReason.trim()) {
      dispatchAlert(dispatch, 'error', 'Please provide a reason for cancellation.');
      return;
    }

    setSubmitting(true);

    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        throw new Error('Failed to retrieve session.');
      }

      const payload = {
        subscription_id: subscriptionInfo.subscription_id,
        cancel_reason: cancelReason === 'Other' ? otherReason.trim() : cancelReason,
      };

      // Log the payload for debugging
      console.log('Cancellation Payload:', payload);

      // Send the cancellation request to the backend
      const response = await fetch(
        'https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/cancel_subscription',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${session['access_token']}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Cancellation Error Response:', errorText);
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      // Log the response data for debugging
      console.log('Cancellation Response:', responseData);

      // Update the subscription info if provided
      if (responseData.updatedRow) {
        setSubscriptionInfo(responseData.updatedRow);
        localStorage.setItem('subscription_data', JSON.stringify(responseData.updatedRow));
      }

      dispatchAlert(dispatch, 'success', 'Subscription canceled successfully.');
      setShowCancelModal(false);
    } catch (error) {
      dispatchAlert(dispatch, 'error', 'Failed to cancel subscription.');
      console.error('Cancellation Error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle modal closure
  const handleModalClose = () => {
    setShowCancelModal(false);
    setCancelReason('');
    setOtherReason('');
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4 sm:space-y-8">
      <NavButtons />

      <div className="space-y-4 bg-slate-900 p-4 sm:p-8 rounded-lg shadow-lg w-full max-w-xl">
        {loading ? (
          <div className="flex items-center space-x-2">
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="text-white">Getting info, please wait...</span>
          </div>
        ) : (
          <>
            {subscriptionInfo &&
            (subscriptionInfo.status_name === 'active' ||
              subscriptionInfo.status_name === 'on_trial') &&
            subscriptionInfo.manually_cancelled !== 'true' ? (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">
                  Congrats on being a Pro user! Your current subscription will be renewed on:{' '}
                  {new Date(subscriptionInfo.renew_date).toDateString()}.
                </p>
                <button
                  className="mt-4 bg-red-500 text-white text-sm sm:text-base p-2 rounded hover:bg-red-600"
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </button>
              </div>
            ) : subscriptionInfo && subscriptionInfo.manually_cancelled === 'true' ? (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">
                  Thanks for being a pro user. Your subscription will end on{' '}
                  {new Date(subscriptionInfo.renew_date).toDateString()}.
                </p>
                <button
                  className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                  onClick={handleContinuePro}
                >
                  Continue being a Pro
                </button>
              </div>
            ) : (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">
                  Become a pro and enjoy all the below benefits
                </p>
                <ul className="text-white text-sm sm:text-base list-disc list-inside">
                  <li>Three Different Meal types</li>
                  <li>Unlimited number of meal resets</li>
                  <li>Unlimited Replace Meals</li>
                  <li>Unlimited workout resets</li>
                  <li>Unlimited Replace Workouts</li>
                  <li>Priority support</li>
                </ul>
                <button
                  className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                  onClick={handleContinuePro}
                >
                  Get Pro
                </button>
                <p className="text-white text-sm sm:text-base mt-4">
                  If you have already purchased a pro subscription but are not seeing it, use the button below to restore your purchase
                </p>
                <button
                  className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                  onClick={restorePurchase}
                  disabled={restoring}
                >
                  {restoring ? 'Restoring...' : 'Restore Purchase'}
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* Cancellation Modal */}
      {showCancelModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-slate-800 p-6 rounded-lg shadow-lg w-11/12 max-w-md">
            <h2 className="text-white text-lg mb-4">Cancel Subscription</h2>
            <form onSubmit={handleCancelSubmit} className="space-y-4">
              <div>
                <label className="block text-white mb-2">Reason for cancellation:</label>
                <select
                  className="w-full p-2 rounded bg-slate-700 text-white"
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select a reason
                  </option>
                  <option value="Too expensive">Too expensive</option>
                  <option value="Not using enough">Not using enough</option>
                  <option value="Found a better alternative">Found a better alternative</option>
                  <option value="Technical issues">Technical issues</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {cancelReason === 'Other' && (
                <div>
                  <label className="block text-white mb-2">Please specify:</label>
                  <input
                    type="text"
                    className="w-full p-2 rounded bg-slate-700 text-white"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder="Enter your reason"
                    required={cancelReason === 'Other'}
                  />
                </div>
              )}

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                  onClick={handleModalClose}
                  disabled={submitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Confirm Cancellation'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateOthers;
