import React from 'react';
import aiImage from '../../assets/main/trainer.jpg';
import closeImage from '../../assets/main/close.png';

const FloatingButton = ({ onClick, isChatOpen }) => {
  return (
    <button 
      className="fixed bottom-7 right-2 bg-transparent border-none p-0 cursor-pointer transform transition-transform duration-300 active:scale-90 z-10"
      onClick={onClick}
      style={{ width: '70px', height: '70px' }}
    >
      <div className="relative w-full h-full">
        {/* Outer ring */}
        <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 p-1 shadow-lg hover:shadow-xl">
          {/* Inner circle with image */}
          <div className="w-full h-full rounded-full overflow-hidden bg-white flex items-center justify-center">
            <img 
              src={isChatOpen ? closeImage : aiImage} 
              alt="AI" 
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        
        {/* Online status indicator */}
        <div className="absolute bottom-1 right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></div>
      </div>
    </button>
  );
};

export default FloatingButton;