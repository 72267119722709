import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert'
import NavButtons from '../Utils/Components/NavButtons'
import ReactGA from "react-ga4";



const UpdateWorkoutInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch  = useDispatch();


  const storedGoal = localStorage.getItem('user_fitness_goal') || 'Lose Weight';
  const storedSelectedDays = JSON.parse(localStorage.getItem('user_preferred_workout_days')) || [];

  const [workoutType, setWorkoutType] = useState(localStorage.getItem('user_workout_type')); // Default: Home Workout
  const [experienceLevel, setExperienceLevel] = useState(localStorage.getItem('user_experience_level')); // Default: Beginner

  const [goal, setGoal] = useState(storedGoal);
  const [selectedDays, setSelectedDays] = useState(storedSelectedDays);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [userGoalTDEE, setUserGoalTDEE] = useState(parseFloat(localStorage.getItem('user_goal_tdee') || "0"));
  const [userTDEE, setUserTDEE] = useState(parseFloat(localStorage.getItem('user_tdee') || "0"));
  

  const workoutTypes = ['Home Workout', 'GYM Workout']; // Added "Outdoor Running"
  const experienceLevels = ['Beginner', 'Intermediate', 'Advanced'];


  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const fitnessGoals = ['Lose Weight', 'Gain Muscle Mass'];

  const updateGoalTDEE = (goal) => {
    console.log('Update goal tdee called', goal);
    let newGoalTDEE = userTDEE;
    if (goal === 'Lose Weight') {
      newGoalTDEE = userTDEE - 400; // Average between 400-500
    } else if (goal === 'Gain Muscle Mass') {
      newGoalTDEE = userTDEE + 400;
    }
    setUserGoalTDEE(newGoalTDEE); // Using the state update function
    console.log('user_goal_tdee', userTDEE, newGoalTDEE);
  }

  const handleSelectGoal = (selectedGoal) => {
    console.log('updateGoalTDEE called');
    setGoal(selectedGoal);
    updateGoalTDEE(selectedGoal);

  };

  const toggleDay = (day) => {
    setSelectedDays(prevDays => {
      if (prevDays.includes(day)) {
        return prevDays.filter(d => d !== day);
      } else {
        return [...prevDays, day];
      }
    });
  };

  useEffect(() => {
    if (notification.message) {
        const timer = setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 5000);

        return () => clearTimeout(timer);  // Clear timer if the component is unmounted
    }
  }, [notification.message]);

  useEffect(() => {
    const initAnalytics = () => {
        ReactGA.send({
            hitType: "pageview",
            page: "/settings/workout",
            title: "Update Workout Info"
        });
    };

    initAnalytics();
  }, []);

  const saveChanges = async () => {

    if (selectedDays.length < 3 || selectedDays.length > 6) {
      dispatchAlert(dispatch,'alert','Please select at least 3 days and at most 6 days for your workout.')
      return; // Exit without making the API call
    }

    setLoading(true);
    // Update local storage
    localStorage.setItem('user_fitness_goal', goal);
    localStorage.setItem('user_preferred_workout_days', JSON.stringify(selectedDays));
    localStorage.setItem('user_workout_type', workoutType);
    localStorage.setItem('user_experience_level', experienceLevel);
    localStorage.setItem('user_goal_tdee', userGoalTDEE);

    // Create payload for API call
    const payload = {
      user_fitness_goal: goal,
      user_preferred_workout_days: selectedDays,
      user_workout_type: workoutType,
      user_experience_level: experienceLevel,
      user_goal_tdee:userGoalTDEE,
      write_table:'my_users'
    };
    console.log('payload',payload)

    // Make API call
    try {
      const {data: { session },} = await supabase.auth.getSession()

      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session['access_token']}`
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if(response.status === 200) {
        dispatchAlert(dispatch,'success','Changes updated successfully')
    } else {
        dispatchAlert(dispatch,'error','Failed to update changes')
    }
      console.log('Data sent to localhost:8000', result);
      setLoading(false);
    } catch (fetchError) {
      setLoading(false);
      console.error('Error sending data to localhost:8000', fetchError);
      dispatchAlert(dispatch,'error','An error occurred. Please try again.')
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-2 sm:p-4 space-y-2 sm:space-y-4">
      <NavButtons />

        {loading ? 
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <p className="text-white">Updating Info.. 🪄✨</p>
            </div>
        : <div></div>}

        <h1 className="text-xl mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
            Let's update your daily calorie needs
          </h1>
          {userTDEE && userGoalTDEE && (
            <p className="text-lg text-gray-300 text-center">
              Your current Calories: {userTDEE} cal | Your target Calories Should Be Around: {userGoalTDEE} cal
            </p>
          )}

        {/* Notification Block */}
        {notification.message && (
            <div className={`w-full sm:w-96 p-4 rounded shadow-lg text-center my-4 
                            ${notification.type === 'success' ? 'bg-blue-400 text-gray-100' : 'bg-slate-700 text-red-400'}`}>
                {notification.message}
            </div>
        )}

        <h1 className="text-xl mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
            Set Your Fitness Goals
        </h1>
        
        <div className="space-y-4 bg-slate-900 p-4 sm:p-8 rounded-lg shadow-lg w-full sm:w-2/3 lg:w-1/3">
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mb-4">
                    Please update your fitness goal:
                </h6>

                <div className="flex flex-col space-y-4">
                    {fitnessGoals.map(fitnessGoal => (
                        <button 
                            key={fitnessGoal}
                            className={`bg-${goal === fitnessGoal ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                            onClick={() => handleSelectGoal(fitnessGoal)}>
                            {fitnessGoal}
                        </button>
                    ))}
                </div>

                        {/* UI for new question: Experience Level */}
                <div className="w-full border-t border-gray-600 mt-4"></div>
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                  What is your experience level?
                </h6>
                <div className="flex flex-col space-y-4">
                  {experienceLevels.map(level => (
                    <button 
                      key={level}
                      className={`bg-${experienceLevel === level ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                      onClick={() => setExperienceLevel(level)}>
                      {level}
                    </button>
                  ))}
                </div>

                <div className="w-full border-t border-gray-600 mt-4"></div>

                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                    Please update the days you'd like to workout:
                </h6>
                <span className="text-gray-400 text-sm">(Select at least 3, 5 for best results, 6 max)</span>

                <div className="flex flex-wrap justify-center mt-2">
                    {daysOfWeek.map(day => (
                        <button
                            key={day}
                            className={`m-1 px-3 py-1.5 rounded-md text-white ${selectedDays.includes(day) ? 'bg-blue-500' : 'bg-gray-900'} hover:bg-slate-700`}
                            onClick={() => toggleDay(day)}>
                            {day}
                        </button>
                    ))}
                </div>

                <div className="w-full border-t border-gray-600 mt-4"></div>
                <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                  Which kind of workout do you prefer?
                </h6>
                <div className="flex flex-col space-y-4">
                  {workoutTypes.map(type => (
                    <button 
                      key={type}
                      className={`bg-${workoutType === type ? 'blue-500' : 'gray-900'} hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-10 px-6 rounded-lg w-full flex items-center justify-center`}
                      onClick={() => setWorkoutType(type)}>
                      {type}
                    </button>
                  ))}
                </div>
                <div className="w-full border-t border-gray-600 mt-4"></div>
                <button
    className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full mt-4 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
    onClick={saveChanges}
    disabled={loading}>
    Save Changes
</button>







            </div>
        </div>
    );

};

export default UpdateWorkoutInfo;
