import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Navigation/Footer';

const TermsOfService = () => {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            {/* Header */}
            <header className="w-full text-center mb-12">
                <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white">
                    Terms of Service
                </h1>
            </header>

            {/* Content */}
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg max-w-5xl w-full mx-auto mb-8 text-gray-300">
                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Introduction</h2>
                    <p>Welcome to GetFitterAI! These terms and conditions outline the rules and regulations for the use of GetFitterAI's Website, located at getfitter.ai. By accessing this website, we assume you accept these terms and conditions. Do not continue to use GetFitterAI if you do not agree to take all of the terms and conditions stated on this page.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Account Terms</h2>
                    <p>By creating an account at GetFitterAI, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must immediately notify GetFitterAI of any unauthorized uses of your account or any other breaches of security. GetFitterAI will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Usage Terms</h2>
                    <p>You are responsible for your usage of GetFitterAI. You agree not to misuse or overuse the services, or help anyone else to do so. You agree not to engage in unacceptable use of the GetFitterAI service, which includes, without limitation, use of the service to: disseminate or transmit unsolicited messages, chain letters, or unsolicited commercial email; disseminate or transmit material that, to a reasonable person, may be abusive, obscene, pornographic, defamatory, harassing, grossly offensive, vulgar, threatening, or malicious; or create a false identity or to otherwise attempt to mislead any person as to the identity, source, or origin of any communication. Furthermore, you agree not to access, or attempt to access, any of the Services by any means other than through the interface that is provided by GetFitterAI.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Copyrights and Trademarks</h2>
                    <p>All content included on this site, such as text, graphics, logos, images, is the property of GetFitterAI or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of GetFitterAI, with copyright authorship for this collection by GetFitterAI, and protected by international copyright laws. Trademarks, logos, and service marks displayed on the site are registered and unregistered trademarks of GetFitterAI, its licensors or content providers, or other third parties. All of these trademarks, logos, and service marks are the property of their respective owners.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Changes to Terms</h2>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Disclaimer</h2>
                    <p>The materials on GetFitterAI's website are provided on an 'as is' basis. GetFitterAI makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, GetFitterAI does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">User Responsibility</h2>
                    <p>Users of GetFitterAI bear the sole responsibility for any and all actions, results, or events that transpire due to their use of the service. This includes, but is not limited to, physical, mental, financial, or other types of outcomes. While GetFitterAI provides tools and resources, how they are used and the results therefrom are entirely up to the user.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Indemnification</h2>
                    <p>You agree to indemnify, defend, and hold harmless GetFitterAI, its officers, directors, employees, agents, licensors, suppliers, and any third party information providers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of this Agreement (including negligent or wrongful conduct) by you or any other person accessing the Service using your account.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Termination of Service</h2>
                    <p>GetFitterAI reserves the right to terminate, suspend, or limit your access to the Service at any time, without notice, for conduct that GetFitterAI believes violates these Terms of Service or is harmful to other users of the Service, GetFitterAI, or third parties, or for any other reason at GetFitterAI's sole discretion. Furthermore, GetFitterAI reserves the right to discontinue any part of the Service at any time, for any reason.</p>
                </section>


                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Limitation of Liability</h2>
                    <p>In no event shall GetFitterAI, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Contact Us</h2>
                    <p>If you have any questions about these Terms, feel free to contact us at:</p>
                    <p>Email: prudhvisbusiness@gmail.com</p>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default TermsOfService;
