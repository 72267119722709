import React, { useState,useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { VerifyAuth, signInSuccess } from './VerifyAuth'; // adjust the path as needed
import {getUserData} from './CompleteAuth'
import ReactGA4 from "react-ga4";


const SignInScreen = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signInError, setSignInError] = useState(null);
    const [sessionObj, setSessionObj] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
        if (sessionString !== null) {
            setIsLoading(true);
            const jsonObject = JSON.parse(sessionString);
            setSessionObj(jsonObject);
            const Bearer = jsonObject['access_token'];
            const fetchData = async () => {
                const route = await getUserData(Bearer);  // Await getUserData
                setIsLoading(false);
                if (route) {
                    console.log("route is true")
                    navigate('/diet');
                }
                else {
                    console.log("route is false")
                    navigate('/goal');
                }
            };
        
            fetchData();
        }
        else {
            console.log("No session found");
        }


    }, [])

    const VerifySession = async () => {
        const { data, error } = await supabase.auth.getSession()
        if (error) {
            return false;
        }
        else {
            return true;
        }
    }

    const CheckSession = async () => {
        console.log("Checking session called");
        const {data: { session },} = await supabase.auth.getSession()
            if (session) {
            const accessToken = session.access_token;
            const route = await getUserData(accessToken);
                setIsLoading(false);
                if (route) {
                    console.log("route is true")
                    navigate('/diet');
                }
                else {
                    console.log("route is false")
                    navigate('/goal');
                }
            } 
            else {
                console.log("User is not logged in or there's no session.");
                setIsLoading(false);
            }

    }

    const initAnalytics = async () => {
        try {
            ReactGA4.event({
                category: "User",
                action: "WEB_user_in_signin_screen"
            });
            
            ReactGA4.send({ 
                hitType: "pageview", 
                page: "WEB_signin_screen" 
            });
            
            console.log('Analytics initialized successfully signin_screen');
        } catch (error) {
            console.error('Error initializing analytics signin_screen:', error);
        }
    };

    useEffect(() => {
        initAnalytics();
    }, []);

    const handleFormSignIn = async (e) => {
        e.preventDefault();
        ReactGA4.event({
            category: "User",
            action: "WEB_regular_signin_initiated"
        });
        setSignInError(null);
    
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });
    
        if (error) {
            console.error("Error signing in:", error.message);
            setSignInError(error.message);
        } else {
            setIsLoading(true);
            CheckSession();
            
        }
    };

    const googleSignIn = async () => {
        ReactGA4.event({
            category: "User", 
            action: "WEB_google_connect_initiated"
        });
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                },
            },
        })
        console.log(data, error);
    
        if (error) {
            console.error("Error signing in:", error.message);
            setSignInError(error.message);
        } else {
            setIsLoading(true);
            CheckSession();
        }
    }
    

    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <p className="text-white">Summoning pixels... 🪄✨</p>
            </div>
        );
    }
    
    

    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Sign In
            </h1>
    
            {signInError && <div className="text-red-500 mb-4 w-96 text-center rounded-lg p-2 bg-slate-900">{signInError}</div>}
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg w-96 mb-8">
                <form onSubmit={handleFormSignIn} className="space-y-4 " >
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="email" className="text-lg font-medium text-gray-100">Email:</label>
                        <input type="email" id="email" placeholder="john.doe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="password" className="text-lg font-medium text-gray-100">Password:</label>
                        <input type="password" id="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <button type="submit" className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">Sign In</button>
                   
                </form>
                <div className="w-full border-t border-gray-600 mt-4"></div> {/* Line between the buttons */}
                <button 
                    onClick={() => googleSignIn()}
                    className="flex items-center justify-center bg-gray-900 py-1 px-2 rounded w-full mt-4 border border-white"
                >
                    <img 
                        src="https://lh4.googleusercontent.com/dF4SjWMw3gDrgeHuCySWP7peSDLZlg0CIhzsOpS_wpYsnWbpVAhDbdfw6qNVqUYjQzA=w2400" 
                        alt="Google Icon" 
                        className="w-8 h-8" 
                    />
                    <span className="text-gray-300 text-base ml-2">Connect with Google</span>
                </button>

            </div>
            
            <div className="mt-4">
                <span className="text-gray-300">Don't have an account? </span>
                <span onClick={() => navigate('/signup')} className="text-slate-400 hover:underline">Sign Up</span>
            </div>

        </div>
    );
    
}

export default SignInScreen;