import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import {getUserData} from './CompleteAuth'
import ReactGA4 from "react-ga4";


const SignUpScreen = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [countryCode, setCountryCode] = useState("+1");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const CheckSession = async () => {
        const {data: { session },} = await supabase.auth.getSession()
            console.log("inside Check Session function")
            if (session) {
            const accessToken = session.access_token;
            const route = getUserData(accessToken);
                setIsLoading(false);
                if (route) {
                    navigate('/diet');
                }
                else {
                    navigate('/goal');
                }
            } 
            else {
                console.log("User is not logged in or there's no session.");
                setIsLoading(false);
            }

    }


    


    const googleSignIn = async () => {
        ReactGA4.event({
            category: "User",
            action: "WEB_google_connect_initiated"
        });
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                },
            },
        })
        console.log(data, error);
    
        if (error) {
            console.error("Error signing in:", error.message);
            setError(error.message);
        } else {
            setIsLoading(true);
            CheckSession();
        }
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        ReactGA4.event({
            category: "User",
            action: "WEB_regular_signup_initiated"
        });
        const { data, error } = await supabase.auth.signUp({
            email,
            password,
            options: {
                data: {
                    user_full_name: fullName,
                    user_email: email,
                    // phone: phoneNumber ? `${countryCode}${phoneNumber}` : null
                }
            }
        });

        if (error) {
            console.error("Error signing up:", error);
            setError(error.message);
        } else {
            setError(null);
            navigate('/goal');
            console.log("User signed up:", data);
            const storedTokenData = localStorage.getItem("sb-ygcptpjdpipejtvnjnlg-auth-token");
            const parsedTokenData = JSON.parse(storedTokenData);
        
            const user_id = parsedTokenData.user.id;

            const payload = {
                user_email: email,
                user_full_name: fullName,
                user_id: user_id,
                write_table:'my_users'

            };

            try {
                
                const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${parsedTokenData['access_token']}`

                    },
                    body: JSON.stringify(payload),
                });

                const result = await response.json();
                console.log('Data sent to supabase', result);
            } catch (fetchError) {
                console.error('Error sending data to supabase', fetchError);
            }
        }
    };

    // Add analytics initialization
    const initAnalytics = async () => {
        try {
            ReactGA4.event({
                category: "User",
                action: "WEB_user_in_signup_screen"
            });
            
            ReactGA4.send({ 
                hitType: "pageview", 
                page: "WEB_signup_screen" 
            });
            
            console.log('Analytics initialized successfully signup_screen');
        } catch (error) {
            console.error('Error initializing analytics signup_screen:', error);
        }
    };

    // Add useEffect for analytics
    useEffect(() => {
        initAnalytics();
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <p className="text-white">Summoning pixels... 🪄✨</p>
            </div>
        );
    }
    
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Signup
            </h1>
            {error && <div className="text-red-500 mt-2">{error}</div>} 
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg w-96 mb-8">    
                <form onSubmit={handleSignUp} className="space-y-4">
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="fullName" className="text-lg font-medium text-gray-100">Full Name:</label>
                        <input type="text" id="fullName" placeholder="John Doe" value={fullName} onChange={(e) => setFullName(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="email" className="text-lg font-medium text-gray-100">Email:</label>
                        <input type="email" id="email" placeholder="john.doe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="password" className="text-lg font-medium text-gray-100">Password:</label>
                        <input type="password" id="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    {/* <div className="flex flex-col space-y-2">
                        <label htmlFor="countryCode" className="text-lg font-medium text-gray-100">Country Code (Optional):</label>
                        <select id="countryCode" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} className="py-2 px-4 border rounded">
                            <option value="+1">+1 (US)</option>
                            <option value="+44">+44 (UK)</option>
                            <option value="+91">+91 (India)</option>
                            <option value="+61">+61 (Australia)</option>
                            <option value="+49">+49 (Germany)</option>
                            <option value="+81">+81 (Japan)</option>
                            <option value="+55">+55 (Brazil)</option>
                            <option value="+86">+86 (China)</option>
                            <option value="+33">+33 (France)</option>
                            <option value="+39">+39 (Italy)</option>
                        </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="phoneNumber" className="text-lg font-medium text-gray-100">Phone Number (Optional):</label>
                        <input type="text" id="phoneNumber" placeholder="2345678901" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="py-2 px-4 border rounded" />
                    </div> */}


                <button type="submit" className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">Sign Up</button>
                </form>
                <div className="text-center text-sm mt-2 text-gray-300">
                    By signing up, you agree to our <a href="/termsofservice" className="text-slate-400 hover:underline">Terms of Service</a> & <a href="/privacypolicy" className="text-slate-400 hover:underline">Privacy Policy</a>.
                </div>
                <div className="w-full border-t border-gray-600 mt-4"></div> {/* Line between the buttons */}
                <button 
                    onClick={() => googleSignIn()}
                    className="flex items-center justify-center bg-gray-900 py-1 px-2 rounded w-full mt-4 border border-white"
                >
                    <img 
                        src="https://lh4.googleusercontent.com/dF4SjWMw3gDrgeHuCySWP7peSDLZlg0CIhzsOpS_wpYsnWbpVAhDbdfw6qNVqUYjQzA=w2400" 
                        alt="Google Icon" 
                        className="w-8 h-8" 
                    />
                    <span className="text-gray-300 text-base ml-2">Connect with Google</span>
                </button>
            </div>
            
            <div className="mt-4">
                <span className="text-gray-300">Already have an account? </span>
                <span onClick={() => navigate('/signin')} className="text-slate-400 hover:underline">Sign in</span>
            </div>

            

        </div>
    );
    
};

export default SignUpScreen;