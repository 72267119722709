import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

const PromoBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="relative w-full bg-gradient-to-r from-purple-600 to-blue-600 text-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 py-3">
        <div className="flex items-center justify-center gap-x-6">
          <div className="flex items-center gap-x-4">
            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-white/20">
              🎉
            </span>
            <div className="flex flex-col sm:flex-row sm:items-center gap-x-2">
              <span className="text-sm font-medium">Black Friday Sale</span>
              <span className="hidden sm:inline">•</span>
              <span className="text-sm">
                Get <span className="font-semibold">40% off</span> with code
              </span>
              <span className="inline-flex items-center rounded-full bg-white/25 px-4 py-1 text-sm font-semibold">
                BLKFRDY40
              </span>
            </div>
          </div>
          
          <button
            onClick={() => setIsVisible(false)}
            className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full p-1 hover:bg-white/20 transition-colors duration-200"
          >
            <XMarkIcon className="h-5 w-5" />
            <span className="sr-only">Dismiss banner</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;