import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; 

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-check text-green-500"
    style={{ height: '1em', width: '1em' }}
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

const PackageOption = ({ title, features, originalPrice, discountedPrice, mostPopular, bestValue, onSelect }) => (
  <div className={`bg-slate-900 p-4 rounded-lg shadow-md mb-4 ${mostPopular ? 'border-l-4 border-orange-500' : ''} ${bestValue ? 'border-l-4 border-blue-300' : ''}`}>
    {mostPopular && <div className="text-right text-sm font-semibold text-orange-500">Most popular 🔥</div>}
    {bestValue && <div className="text-right text-sm font-semibold text-blue-300">Best value 💎</div>}
    <h3 className="font-bold text-lg mb-2 text-gray-100">{title}</h3>
    <ul className="list-none space-y-2 mb-4">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-300">
          <CheckmarkIcon />
          <span className="ml-2">{feature}</span>
        </li>
      ))}
    </ul>
    <div className="flex justify-between items-center mt-4 text-gray-300">
      <div>
        <span className="line-through mr-2">${originalPrice}</span>
        <span className="text-lg font-bold">${discountedPrice}</span>
      </div>
      <button 
        className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-slate-600" 
        onClick={onSelect}
      >
        Select
      </button>
    </div>
  </div>
);

const PricingOverlay = ({ isVisible, onClose }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [email, setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName, setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID, setUserID] = useState(localStorage.getItem('user_id') || '');

  const url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`;

  const handleUpgradeClick = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isVisible) return null;

  const overlayStyle = {
    left: isMobile ? '0px' : '256px',
    width: isMobile ? '100%' : `calc(100% - 256px)`,
    bottom: isMobile ? '50px' : '0px',
    height: isMobile ? 'calc(100vh - 50px)' : '100vh',
  };

  const overlayContent = (
    <div 
      className="fixed inset-0 z-50 flex items-start justify-center overflow-y-auto py-4" 
      style={{ ...overlayStyle, pointerEvents: 'auto' }}
    >
      <div 
        className={`
          bg-gradient-to-r from-slate-900 to-slate-800 
          p-6 rounded-lg flex flex-col items-center 
          space-y-4 mx-auto my-4
          ${isMobile ? 'w-[95%]' : 'w-full max-w-md'} 
          border border-slate-600 shadow-lg
          relative
        `}
        style={{ maxHeight: isMobile ? 'calc(100vh - 80px)' : 'calc(100vh - 40px)' }}
      >
        <button 
          className="absolute top-4 right-4 text-gray-300 hover:text-white focus:outline-none p-2"
          onClick={onClose}
          aria-label="Close overlay"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <div className="w-full overflow-y-auto pt-8">
          <h1 className="text-white font-bold text-2xl mb-2 text-center">Select a package</h1>
          <div className="flex items-center justify-center space-x-2 mb-4">
            <CheckmarkIcon />
            <h2 className="text-white font-bold text-lg">7 days free trial, cancel anytime</h2>
          </div>
          
          <div className="w-full space-y-4">
            <PackageOption
              title="Pro (Paid monthly)"
              features={['Three Different Meal types', 'Unlimited no of meal resets', 'Unlimited Replace Meals', 'Unlimited workout resets', 'Unlimited Replace Workouts', 'Priority support']}
              originalPrice="9.99"
              discountedPrice="4.99"
              mostPopular
              onSelect={handleUpgradeClick}
            />
            <PackageOption
              title="PRO (Paid yearly)"
              features={['Three Different Meal types', 'Unlimited no of meal resets', 'Unlimited Replace Meals', 'Unlimited workout resets', 'Unlimited Replace Workouts', 'Priority support']}
              originalPrice="99.99"
              discountedPrice="49.99"
              bestValue
              onSelect={handleUpgradeClick}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    overlayContent,
    document.getElementById('overlay-root')
  );
};

export default PricingOverlay;