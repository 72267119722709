

const clearLocalStorageData = () => {
    console.log("clearLocalStorageData called");

    // Keys to preserve
    const keysToPreserve = [
        "sb-ygcptpjdpipejtvnjnlg-auth-token",
        "hasVisitedDietScreen",
        "hasVisitedWorkoutScreen"
    ];

    // Save all values we want to keep
    const preservedValues = {};
    keysToPreserve.forEach(key => {
        const value = localStorage.getItem(key);
        if (value !== null) {
            preservedValues[key] = value;
        }
    });

    // Clear all localStorage
    localStorage.clear();

    // Restore preserved values
    Object.entries(preservedValues).forEach(([key, value]) => {
        localStorage.setItem(key, value);
    });
};


const ExtractEmail = () =>{
    const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
    const jsonObject = JSON.parse(sessionString)
    if (jsonObject.user.app_metadata.provider == "google") {
        // Extract the email
        var user_email = jsonObject.user.email;
        console.log("Extracted Email:",user_email)
        // Save to local storage under the key 'user_email'
        localStorage.setItem('user_email', user_email);
    }
}


export const getUserData = (sessionObj) => {
    return new Promise((resolve, reject) => {
        console.log("Getting user data for ");

        fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_user_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionObj}`
            },
            body: JSON.stringify({
                table: 'my_users',
                // Add any other necessary data
              })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Successfully retrieved user data:', data);
            
            clearLocalStorageData();
            
            for (const key in data) {
                localStorage.setItem(key, data[key]);
            }
             
            if ('user_goal_tdee' in data && data['user_goal_tdee'] !== null) {
                console.log("user_goal_tdee exists in data");
                
                // Check for the key in local storage
                const endTime = Date.now() + 60000;  // 1 minute from now
                const checkLocalStorage = () => {
                    const value = localStorage.getItem('user_goal_tdee');
                    if (value) {
                        console.log(`user_goal_tdee value found :${value}`);
                        resolve(true);  // Found the value
                    } else if (Date.now() >= endTime) {
                        reject(new Error("Timed out waiting for user_goal_tdee in local storage"));
                    } else {
                        setTimeout(checkLocalStorage, 100);  // Check again in 100ms
                    }
                }
                ExtractEmail();
                checkLocalStorage();  // Start the checking loop
                
            } else {
                console.log("user_goal_tdee does not exist in data");
                ExtractEmail();
                clearLocalStorageData();
                resolve(false);  // Resolve the promise with false
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            clearLocalStorageData();
            resolve(false);
        });
    });
}


